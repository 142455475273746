var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"customer-record"}},[_c('c-nav-bar',{attrs:{"title":"客户账本明细"}}),_c('div',{staticClass:"content"},[(_vm.data.length)?_c('van-pull-refresh',{attrs:{"success-text":"刷新成功"},on:{"refresh":_vm.handleRefreshList},model:{value:(_vm.refreshing),callback:function ($$v) {_vm.refreshing=$$v},expression:"refreshing"}},[_c('van-list',{attrs:{"finished":_vm.finished,"offset":100},on:{"load":_vm.handleLoadList},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.data),function(item,key){return _c('div',{key:key,staticClass:"panel"},[_c('van-row',{attrs:{"type":"flex","justify":"space_between"}},[_c('van-col',{attrs:{"span":"16"}},[_vm._v(" "+_vm._s(item.order_id)+" ")]),_c('van-col',{staticClass:"tar",attrs:{"span":"8"}},[_vm._v(" "+_vm._s(_vm._f("formatAccountBookType")(item.type))+" ")])],1),_c('van-row',{attrs:{"type":"flex","justify":"space_between"}},[_c('van-col',{attrs:{"span":"12"}},[_c('label',{directives:[{name:"show",rawName:"v-show",value:(
                  item.type === 1 ||
                  item.type === 4 ||
                  item.type === 5 ||
                  item.type === 7 ||
                  item.type === 8 ||
                  item.type === 10 ||
                  item.type === 11 ||
                  item.type === 13 ||
                  item.type === 14 ||
                  item.type === 15 ||
                  item.type === 17 ||
                  item.type === 19
                ),expression:"\n                  item.type === 1 ||\n                  item.type === 4 ||\n                  item.type === 5 ||\n                  item.type === 7 ||\n                  item.type === 8 ||\n                  item.type === 10 ||\n                  item.type === 11 ||\n                  item.type === 13 ||\n                  item.type === 14 ||\n                  item.type === 15 ||\n                  item.type === 17 ||\n                  item.type === 19\n                "}],staticClass:"green"},[_vm._v("+"+_vm._s(item.money))]),_c('label',{directives:[{name:"show",rawName:"v-show",value:(
                  item.type === 2 ||
                  item.type === 3 ||
                  item.type === 6 ||
                  item.type === 9 ||
                  item.type === 12 ||
                  item.type === 16 ||
                  item.type === 18 ||
                  item.type === 21
                ),expression:"\n                  item.type === 2 ||\n                  item.type === 3 ||\n                  item.type === 6 ||\n                  item.type === 9 ||\n                  item.type === 12 ||\n                  item.type === 16 ||\n                  item.type === 18 ||\n                  item.type === 21\n                "}],staticClass:"red"},[_vm._v("-"+_vm._s(item.money))]),_vm._v(" 余额：¥"+_vm._s(item.balance_after))]),_c('van-col',{staticClass:"tar",attrs:{"span":"12"}},[_vm._v(_vm._s(_vm._f("formatDateTime")(item.created_at)))])],1)],1)}),0)],1):_c('van-empty',{attrs:{"description":"暂无记录"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }