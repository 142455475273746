<template>
  <div id="customer-record">
    <c-nav-bar title="客户账本明细"> </c-nav-bar>
    <div class="content">
      <van-pull-refresh
        v-if="data.length"
        v-model="refreshing"
        success-text="刷新成功"
        @refresh="handleRefreshList"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          @load="handleLoadList"
          :offset="100"
        >
          <div class="panel" v-for="(item, key) in data" :key="key">
            <van-row type="flex" justify="space_between">
              <van-col span="16">
                {{ item.order_id }}
              </van-col>
              <van-col span="8" class="tar">
                {{ item.type | formatAccountBookType }}
              </van-col>
            </van-row>
            <van-row type="flex" justify="space_between">
              <van-col span="12">
                <label
                  class="green"
                  v-show="
                    item.type === 1 ||
                    item.type === 4 ||
                    item.type === 5 ||
                    item.type === 7 ||
                    item.type === 8 ||
                    item.type === 10 ||
                    item.type === 11 ||
                    item.type === 13 ||
                    item.type === 14 ||
                    item.type === 15 ||
                    item.type === 17 ||
                    item.type === 19
                  "
                  >+{{ item.money }}</label
                >
                <label
                  class="red"
                  v-show="
                    item.type === 2 ||
                    item.type === 3 ||
                    item.type === 6 ||
                    item.type === 9 ||
                    item.type === 12 ||
                    item.type === 16 ||
                    item.type === 18 ||
                    item.type === 21
                  "
                  >-{{ item.money }}</label
                >

                余额：¥{{ item.balance_after }}</van-col
              >
              <van-col class="tar" span="12">{{
                item.created_at | formatDateTime
              }}</van-col>
            </van-row>
          </div>
        </van-list>
      </van-pull-refresh>
      <van-empty v-else description="暂无记录"></van-empty>
    </div>
  </div>
</template>

<script>
import CNavBar from "@/components/CNavBar.vue";

import customer from "@/api/customer";

export default {
  name: "customer-record",
  data() {
    return {
      data: [],
      refreshing: false, // 下拉刷新
      finished: false, // 上拉加载完毕
      loading: false, // 上拉加载
      page: 1,
    };
  },
  created() {
    this.handleList();
  },
  components: {
    CNavBar,
  },
  methods: {
    handleRefreshList() {
      this.page = 1;
      this.refreshing = true;
      this.handleList();
    },
    handleLoadList() {
      this.page++;
      this.loading = true;
      this.handleList();
    },
    handleList() {
      let params = {
        page: this.page,
      };
      customer
        .listRecord(this.$route.query.pk, params)
        .then((res) => {
          if (this.data.length > 0) {
            if (this.loading) {
              this.data = this.data.concat(res.results);
              this.$nextTick(() => {
                this.loading = false;
              });
            }
            if (this.refreshing) {
              this.refreshing = false;
              this.data = res.results;
              if (this.finished) {
                this.finished = false;
              }
            }
          } else {
            this.data = res.results;
          }

          if (res.results.length < 10) {
            this.finished = true;
          }
        })
        .finally(() => {
          this.refreshing = false;
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="stylus" scoped>
#customer-record {
  height: 100%;

  .content {
    position: fixed;
    left: 0;
    right: 0;
    top: 56px;
    bottom: 0;
    background: #ffffff;
    overflow-y: scroll;
    padding: 0 16px;

    .panel {
      width: 100%;
      border-bottom: 1px solid #ececec;
      font-size: 0.875rem;
      color: #666;
      padding: 10px 0;
    }
  }
}
</style>
